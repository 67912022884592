<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">物联网卡</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="单位">
					<a-select placeholder="请选择" v-model="query.hospital_id">
						<a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="硬件编号">
					<a-input v-model="query.version" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="ICCID">
					<a-input v-model="query.version" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item prop="type" label="设备状态">
					<a-select placeholder="请选择" v-model="query.status">
						<a-select-option :value="item.key" v-for="item in iotStatus" :key="item.class_id">
							{{ item.value }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="firmware_id" style="margin-top: 14px"
			v-viewer>
			<span slot="area" slot-scope="text, record">
				<template v-if="hospital_name == null">
					-
				</template>
				<template v-if="hospital_name != null">
					{{ record.hospital_name + '-' + record.department_name + '-' + record.hos_number }}
				</template>
			</span>
			<span slot="use" slot-scope="text, record">
				<a-space>
					{{ record.data_usage }}MB&nbsp;/&nbsp;{{ record.data_traffic_amount }}MB
				</a-space>
			</span>
			<span slot="out" slot-scope="text, record">
				<a-space>
					{{ record.out_data_usage }}MB
				</a-space>
			</span>
			<span slot="status" slot-scope="text, record">
				<a-space>
					{{ getName(iotStatus, record.account_status, "key", "value") }}
				</a-space>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a-popconfirm title="确认删除" ok-text="是" cancel-text="否" @confirm="del(record.firmware_id)">
						<a style="color: #E0352B">删除</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			form: {
				firmware_url_name: ""
			},
			iotStatus: [],
			pagetotal: 0,
			bind_id: null,
			hospitals: [],
			confirmLoading: false,
			columns: [
				{
					title: '设备编号',
					dataIndex: 'equipment_number'
				},
				{
					title: '硬件编号',
					dataIndex: 'hardware_number'
				},
				{
					title: '单位',
					scopedSlots: {
						customRender: 'area'
					}
				},
				{
					title: 'ICCID(物联网号)',
					dataIndex: 'iot_card'
				},
				{
					title: '当前使用',
					scopedSlots: {
						customRender: 'use'
					}
				},
				{
					title: '超出',
					scopedSlots: {
						customRender: 'out'
					}
				},
				{
					title: '卡状态',
					scopedSlots: {
						customRender: 'status'
					}
				},
				{
					title: '到期时间',
					dataIndex: 'expiry_date'
				}
			],
			data: [],
		}
	},
	created() {
		this.getList()
		this.getHospitals()
		this.getIotStatus()
	},
	computed: {
		token() {
			return this.$store.state.token
		}
	},
	methods: {
		getIotStatus() {
			this.$post('iotDevice/statusListNoPage', {})
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.iotStatus = data
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		getHospitals() {
			this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
				if (res.code === 0) {
					this.hospitals = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/iotDevice/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList();
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
